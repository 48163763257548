import { css } from "styled-components";
import { breakpoints, spacing } from "./variables";

export const SectionVerticalPadding = () => css`
  padding: ${spacing.sectionPaddingLaptop} 0;

  @media (max-width: ${breakpoints.tablet}) {
    padding: ${spacing.sectionPaddingTablet} 0;
  }

  @media (max-width: ${breakpoints.mobile}) {
    padding: ${spacing.sectionPaddingMobile} 0;
  }
`;

export const SectionVerticalPaddingTopDouble = () => css`
  padding: calc(${spacing.sectionPaddingLaptop} * 2) 0 ${spacing.sectionPaddingLaptop};

  @media (max-width: ${breakpoints.tablet}) {
    padding: calc(${spacing.sectionPaddingTablet} * 2.5) 0 ${spacing.sectionPaddingTablet};
  }

  @media (max-width: ${breakpoints.mobile}) {
    padding: calc(${spacing.sectionPaddingMobile} * 2) 0 ${spacing.sectionPaddingMobile};
  }
`;
