import styled from "styled-components";
import { StyledForm, StyledField, StyledButton } from "../Riot/EmailForm/style";
import { FormErrorMessage, MessageWrapper } from "../Riot/EmailForm/components/Error/style";
import { colors, breakpoints, eases } from "../../styles/variables";

export const NewsletterSection = styled.section`
  position: relative;
  background-color: ${colors.darkGreen};
  background-position: center bottom;
  background-size: auto 80%;
  background-repeat: no-repeat;
  padding: 35px 0 90px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .razorImage {
    grid-column: 1 / span 12;
    grid-row: 1;
    margin: 30px 0 30px 0;
    position: absolute;
    width: 648px;
    height: 321px;
    left: 907px;
    top: -40px;

    @media (max-width: ${breakpoints.desktop}) {
      grid-column: 1 / span 12;
      grid-row: 1;
      margin: 30px 0 30px 0;
      position: absolute;
      max-width: 648px;
      height: 321px;
      left: 907px;
      top: -40px;
    }

    @media (max-width: ${breakpoints.tablet}) {
      grid-column: 2 / span 6;
      grid-row: 1;
      position: relative;
      width: 422px;
      height: 208px;
      left: calc(50% - 422px / 2 + 32px);
      top: 0px;
    }

    @media (max-width: ${breakpoints.mobile}) {
      grid-column: 1 / span 2;
      grid-row: 1;
      width: 287px;
      height: 142px;
      left: calc(50% - 287px / 2 + 4px);
    }
  }
`;

export const TitleContainer = styled.div`
  grid-column: 2 / span 10;
  grid-row: 2;
  margin: 230px 0 30px 0;

  h2 {
    font-family: "Beaufort-Bold";
    font-size: 72px;
    line-height: 100%;
    color: ${colors.teal};
    text-transform: uppercase;
    margin: 0 0 16px 0;
    position: relative;
  }

  p {
    font-family: "Beaufort-Regular";
    font-size: 20px;
    line-height: 130%;
    color: ${colors.gold};
    text-transform: uppercase;
  }

  @media (max-width: ${breakpoints.tablet}) {
    grid-column: 2 / span 6;
    margin: 0 0 40px 0;

    > h2 {
      font-size: 32px;
    }

    > p {
      font-size: 24px;
    }
  }

  @media (max-width: ${breakpoints.mobile}) {
    grid-column: 1 / span 2;
    margin: 0 0 30px 0;

    > h2 {
      margin: 40px 0 24px 0;
    }

    > p {
      font-size: 18px;
    }
  }
`;

export const FormContainer = styled.div`
  position: relative;
  grid-column: 2 / span 10;
  grid-row: 3;

  ${StyledForm} {
    display: block;
    padding: 0;

    .post-signup {
      width: 100%;
      height: initial;
      min-width: initial;
      max-width: initial;
      min-height: initial;
      padding: 0;
      border: 0;
      background: transparent;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 24px;
      font-family: inherit;
    }

    .post-signup-content {
      width: 100%;
      padding: 48px 72px;
      background: ${colors.black};
      border: 4px solid ${colors.teal};
      box-shadow: 8px 8px 0 ${colors.teal};

      h1 {
        font-size: 22px;
      }

      @media (max-width: ${breakpoints.tablet}) {
        padding: 28px 42px;

        h1 {
          font-size: 18px;
        }
      }
    }

    .signup {
      width: initial;
      height: initial;
      min-width: initial;
      max-width: initial;
      min-height: initial;
      padding: 0;
      border: 0;
      background: transparent;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 24px;
      font-family: inherit;

      h1 {
        display: none;
      }
    }
  }

  ${StyledField} {
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
    flex: 2;

    label {
      color: ${colors.black};
    }

    label.floating {
      opacity: 0.6;
    }

    input[type="text"] {
      display: block;
      height: 54px;
      border: none;
      border-radius: 0;
      background-color: ${colors.cream};
      font-family: "ProximaNova-Bold";
      font-size: 17px;
      letter-spacing: 0;
      color: ${colors.black};
      -webkit-text-fill-color: ${colors.black};
      -webkit-box-shadow: 0 0 0px 1000px ${colors.cream} inset;

      ::placeholder {
        font-family: "ProximaNova-Bold";
        font-size: 17px;
      }

      :-webkit-autofill::first-line {
        font-family: "ProximaNova-Bold";
        font-size: 17px;
      }
    }

    input[type="text"].error {
      outline: 3px solid ${colors.pink};
      outline-offset: -5px;

      @media (max-width: ${breakpoints.tablet}) {
        margin-bottom: 10px;
      }
    }

    @media (max-width: ${breakpoints.tablet}) {
      flex: 1 0 100%;
    }
  }

  ${StyledButton} {
    margin: 0;
    border-radius: 0;

    display: inline-block;
    font-family: "ProximaNova-Black";
    font-size: 20px;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    padding: 12px 32px;
    cursor: pointer;
    position: relative;

    // Default is teal theme
    color: ${colors.white};
    border: 4px solid ${colors.teal};
    box-shadow: 4px 4px ${colors.teal};
    background-size: 250% 100%;
    background-position: 0 0;
    background-image: linear-gradient(30deg, ${colors.teal} 50%, ${colors.black} 51%);

    transition: all 0.2s ease-out, color 0.3s ease-out, background-position 0.5s ${eases.inOut};

    &:hover {
      transform: translate(2px, 2px);
      background-position: 100% 0;
      color: ${colors.teal};
      box-shadow: 2px 2px ${colors.teal};
    }
    &:active {
    }
    &:disabled {
      opacity: 0.5;
      pointer-events: none;
      box-shadow: none;
    }
  }

  ${MessageWrapper} {
    @media (max-width: ${breakpoints.tablet}) {
      position: relative;
    }
  }

  ${FormErrorMessage} {
    padding-top: 10px;
    color: #fff;
    font: inherit;

    .img-container {
      display: none;
    }

    @media (max-width: ${breakpoints.tablet}) {
      padding: 0;
    }
  }

  @media (max-width: ${breakpoints.tablet}) {
    grid-column: 2 / span 6;

    form input {
      flex: 3;
    }
  }

  @media (max-width: ${breakpoints.mobile}) {
    grid-column: 1 / span 2;
  }
`;

export const BirthdayInputContainer = styled.div`
  flex: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${colors.cream};
  font-family: "ProximaNova-Regular";
  font-size: 16px;
  line-height: 170%;
  padding: 0 12px;

  input {
    background-color: transparent;
    border: none;
    width: 50px;
    padding: 17px 0 17px 12px;
  }

  @media (max-width: ${breakpoints.tablet}) {
    flex: 1 0 100%;
  }
`;

export const LegalContainer = styled.div`
  grid-column: 2 / span 10;
  grid-row: 1;
  align-items: left;
  display: flex;
  margin: 64px 0 0 0;

  a {
    color: ${colors.cream};
  }

  > p {
    font-family: "ProximaNova-Regular";
    font-size: 16px;
    line-height: 170%;
    color: ${colors.cream};
    margin: 0 0 0 0px;
    flex: 1;
  }

  input[type="checkbox"] {
    height: 0;
    width: 0;
    visibility: hidden;
  }

  label {
    cursor: pointer;
    text-indent: -9999px;
    width: 40px;
    height: 20px;
    background: ${colors.cream};
    display: block;
    border-radius: 100px;
    position: relative;
    transition: color 0.3s ease-out;
    flex: 0 1 auto;
  }

  label:after {
    content: "";
    position: absolute;
    top: 2px;
    left: 2px;
    width: 16px;
    height: 16px;
    background: ${colors.black};
    border-radius: 16px;
    transition: 0.3s;
  }

  input:checked + label {
    background: ${colors.black};
  }

  input:checked + label:after {
    left: calc(100% - 2px);
    transform: translateX(-100%);
    background: ${colors.peach};
  }

  label:active:after {
    width: 30px;
  }
`;
