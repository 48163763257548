import React from "react";
import styled from "styled-components";
import { breakpoints, zIndices } from "../styles/variables";

const StyledContainer = styled.div`
  width: 100%;
`;

const ClippedContainer = styled.div`
  position: relative;
  box-sizing: content-box;
  width: 100%;
  height: 100%;
  z-index: ${zIndices.lightningClip};
  clip-path: polygon(
    0px 0px,
    100% 0px,
    100% calc(100% - 2vw),
    21% calc(100% - 13vw),
    22% calc(100% - 6.25vw),
    0px calc(100% - 9.5vw)
  );
  padding-bottom: 15vw;

  @media (max-width: 2136px) and (max-height: 1800px) and (min-height: 1200px) {
    height: 70vh;
  }
`;

const SVGLightningLines = styled.svg`
  display: block;
  position: absolute;
  left: 0;
  bottom: 0vw;
  width: 100%;
  height: auto;
  z-index: ${zIndices.lightningClip};
  opacity: 0.3;
`;

type Props = {
  children: JSX.Element | JSX.Element[];
};

function HeroLightningClippedContainer(props: Props) {
  return (
    <StyledContainer>
      <ClippedContainer>{props.children}</ClippedContainer>
      <SVGLightningLines
        width="1440"
        height="196"
        viewBox="0 0 1440 196"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M-867 -37.1006L339.5 135.399L322 36.3994L2429 334.899" stroke="white" />
        <path d="M-859 -45.1006L347.5 127.399L330 28.3994L2437 326.899" stroke="white" />
      </SVGLightningLines>
    </StyledContainer>
  );
}
export default HeroLightningClippedContainer;
