import styled from "styled-components";
import { colors, breakpoints } from "../../styles/variables";

export const FullCarouselSection = styled.section`
  position: relative;
  background: ${colors.black};

  .carouselBox {
    position: relative;
  }
  .carousel {
    width: 100%;
    height: 56.25vw;
    position: relative;
    border-top: 1px solid ${colors.teal};
    border-bottom: 1px solid ${colors.teal};
  }

  .imgSlide {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .slideTitle {
    font-family: "ProximaNova-Bold";
    position: relative;
    color: ${colors.gold};
    font-size: 20px;
    text-transform: uppercase;
  }

  .arrowLeft,
  .arrowRight {
    position: absolute;
    top: 50%;
    margin-top: -25px;
  }
  .arrowRight {
    right: 5px;
  }
  .bulletRow {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 30px;
  }

  .bullets {
    display: flex;
  }
  .pagBullet {
    text-align: center;
    width: 17px;
    height: 17px;
    margin: 0 12px;
    border: 2px solid ${colors.gold};
    border-radius: 17px;
    cursor: pointer;
    .inner {
      width: 100%;
      height: 100%;
      background-color: ${colors.green};
      border-radius: 17px;
      transform: scale(0);
      transition: transform 0.3s linear;
    }

    &.active {
      .inner {
        transform: scale(1.1);
      }
    }
  }
  @media (max-width: ${breakpoints.tablet}) {
  }

  @media (max-width: ${breakpoints.mobile}) {
    .carousel {
      height: 85vw;
    }
    .centerColumn {
      order: 3;
      flex-basis: 100%;
      margin: 20px 0 0 0;
    }
  }
`;
