import styled from "styled-components";
import { breakpoints, colors, zIndices } from "../../styles/variables";

export const HeroSection = styled.section`
  width: 100%;
  position: relative;
  background: ${colors.black};
  .backgroundImg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: center center / cover no-repeat;
  }
  .char {
    position: absolute;
    background: center center / contain no-repeat;
    pointer-events: none;
  }
  .pikeLeft {
    position: absolute;
    min-width: 700px;
    top: 0vw;
    left: -2vw;
    height: 1000px;
    background: center center / contain no-repeat;
  }
  .dustBGTop {
    position: absolute;
    width: 105%;
    height: 100%;
    top: -5vw;
    right: -2vw;
    background: top center / contain no-repeat;
  }
  .dustBot {
    position: absolute;
    width: 105%;
    height: 100%;
    bottom: 0vw;
    left: -3vw;
    background: bottom center / contain no-repeat;
  }

  .charRuinedKing {
    position: absolute;
    top: -3vw;
    right: calc(50% - (var(--width) * 1.3));
    min-width: 100%;
    height: 100%;
    background: right top/ contain no-repeat;
  }
  .centerCol {
    position: relative;
    width: 100%;
    min-height: calc(100vh - 80px);
    max-width: 1440px;
    padding: 0 105px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    .mainLogo {
      width: 45vh;
      max-width: 285px;
      margin: 40px 0px 24px 0px;
    }
    .infoGroup {
      text-align: center;
      width: 383px;
    }
    .available {
      font-size: 20px;
      margin-bottom: 16px;
      color: ${colors.teal};
    }

    .text-wrapper {
      display: flex;
      justify-content: center;
    }
    .text-paragraph {
      font-family: "ProximaNova-light";
      text-align: center;
      margin-bottom: 24px;
      max-width: 382px;
      color: ${colors.white};
    }
    .platforms {
      svg {
        display: inline-block;
        width: 35px;
        height: 35px;
        margin: 0 10px;
        position: relative;
      }

      span:nth-child(1),
      span:nth-child(2) {
        svg {
          width: 100px;
        }
      }
      span:nth-child(3) {
        svg {
          width: 70px;
        }
      }

      path {
        fill: ${colors.gold};
        transition: fill 0.3s ease-out;
      }
    }
  }


  @media (max-width: ${breakpoints.tablet}) {
    .centerCol {
      padding: 0 70px;

      .text-paragraph {
        max-width: 236px;
      }
    }

    .dustBGTop {
      position: absolute;
      width: 105%;
      height: 100%;
      top: -5vw;
      right: -2vw;
      background: top center / contain no-repeat;
    }

    .charRuinedKing {
      display: none;
    }

    .charRuinedKingTablet {
      position: absolute;
      top: 14vw;
      right: -4vw;
      min-width: 700px;
      height: 90%;
      background: right bottom/ contain no-repeat;
    }
  }


  }

  @media (max-width: ${breakpoints.mobile}) {
    .centerCol {
      min-height: 900px;
    }

    .dustBGTop {
      position: absolute;
      width: 105%;
      height: 100%;
      top: -5vw;
      right: -2vw;
      background: top center / contain no-repeat;
    }

    .charRuinedKing {
      display: none;
    }
    .charRuinedKingTablet {
      display: none;
    }

    .charRuinedKingMobile {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 400px;
      background: bottom center / contain no-repeat;
    }
    .centerCol {
      padding: 0 24px;
      .mainLogo {
        width: 80%;
        max-width: 300px;
      }
      .infoGroup {
        width: 350px;
      }
      .available {
        font-size: 18px;
        line-height: 130%;
        margin: 0px;
      }
      .text-paragraph {
        line-height: 1.5em;
        margin: 0px 0 20px;
      }
      .platforms {
        svg {
          display: inline-block;
          width: 30px;
          height: 30px;
          margin: 8px;
          position: relative;
        }

        span:nth-child(1),
        span:nth-child(2) {
          svg {
            width: 100px;
          }
        }
        span:nth-child(3) {
          svg {
            width: 75px;
          }
        }

        path {
          fill: ${colors.gold};
          transition: fill 0.3s ease-out;
        }
      }
    }
  }
`;
