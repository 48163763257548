import React from "react";
import { graphql, navigate } from "gatsby";
import { ContentstackImg } from "@riotgames/wwpub-components";
import ButtonText from "../buttons/ButtonText";
import VideoPreview from "../VideoPreview";
import HeroLightningClippedContainer from "../HeroLightningClippedContainer";
import imgBackground from "../../images/hero/backgroundHero.jpg";
import imgBackgroundDust from "../../images/hero/heroDust.png";
import imgBackgroundDustBot from "../../images/hero/heroDustBot.png";
import heroChar from "../../images/hero/ruinedKingHeroChar.png";
import heroCharTablet from "../../images/hero/ruinedKingHeroTablet.png";
import heroCharMobile from "../../images/hero/ruinedKingHeroMobile.png";

import { HeroSection } from "./styles";

export const fragment = graphql`
  fragment HeroMarqueeFragment on Contentstack_casper_home_page {
    heroMarquee: home_page_hero_marquee {
      gameLogo: game_logo {
        url
      }
      videoLink: video_link {
        title
        href
      }
      inlineVideoFile: inline_video_file {
        url
      }
      videoThumb: video_thumb {
        url
      }
      platforms {
        title
        platformLogo: platform_logo {
          svg {
            content
          }
        }
      }
      videoDescriptor: video_descriptor
      flavorCopy: flavor_copy
      buyPreorderCta: buy_preorder_cta {
        title
        href
      }
    }
  }
`;

export interface HeroData {
  gameLogo: {
    url: string;
  };
  videoLink: {
    title: string;
    href: string;
  };
  inlineVideoFile: {
    url: string;
  };
  videoThumb: {
    url: string;
  };
  platforms: [
    {
      title: string;
      platformLogo: {
        svg: {
          content: string;
        };
      };
    },
  ];
  videoDescriptor: string;
  flavorCopy: string;
  buyPreorderCta: {
    title: string;
    href: string;
  };
}

interface Props {
  data: HeroData;
  videoOpen: Function;
}

interface Vec2 {
  x: number;
  y: number;
}

// Tween to target using Zeno's Paradox
function zTween(_val: number, _target: number, _ratio: number): number {
  return Math.abs(_target - _val) < 1e-5 ? _target : _val + (_target - _val) * Math.min(_ratio, 1.0);
}

export default class Hero extends React.Component<Props> {
  private dustBGBottom: React.RefObject<HTMLDivElement>;
  private dustBGTop: React.RefObject<HTMLDivElement>;
  private charRuinedKing: React.RefObject<HTMLDivElement>;
  private targetPos: Vec2;
  private actualPos: Vec2;
  private raf: number = 0;

  constructor(props: Props) {
    super(props);
    this.dustBGBottom = React.createRef();
    this.dustBGTop = React.createRef();
    this.charRuinedKing = React.createRef();
    this.targetPos = { x: 0, y: 0 };
    this.actualPos = { x: 0, y: 0 };
  }

  scrollToNewsletter = () => {
    document.getElementById("Newsletter")?.scrollIntoView({ inline: "center" });
  };

  onMouseMove = (event: MouseEvent | TouchEvent) => {
    let x = 0,
      y = 0;
    if ("touches" in event && event.touches.length) {
      x = event.touches[0].pageX;
      y = event.touches[0].pageY;
    } else if ("clientX" in event) {
      x = event.clientX;
      y = event.clientY;
    }
    this.targetPos.x = (x / window.innerWidth) * -1.0 + 1.0;
    this.targetPos.y = (y / window.innerHeight) * -1.0 + 1.0;
  };

  translate(ref: React.RefObject<HTMLDivElement>, multiplier: number) {
    if (ref.current) {
      ref.current.style.transform = `translate(${this.actualPos.x * multiplier}vw, ${this.actualPos.y * multiplier}vw)`;
    }
  }

  onUpdate = () => {
    // Smooth tweening towards targetPos
    this.actualPos.x = zTween(this.actualPos.x, this.targetPos.x, 0.1);
    this.actualPos.y = zTween(this.actualPos.y, this.targetPos.y, 0.1);
    this.raf = window.requestAnimationFrame(this.onUpdate);

    // Cancel if tween complete
    if (this.actualPos.x === this.targetPos.x && this.actualPos.y === this.targetPos.y) return;

    this.translate(this.dustBGBottom, 2.0);
    this.translate(this.dustBGTop, 3.0);
    this.translate(this.charRuinedKing, 1.0);
  };

  componentDidMount() {
    window.addEventListener("mousemove", this.onMouseMove);
    window.addEventListener("touchmove", this.onMouseMove);
    this.raf = window.requestAnimationFrame(this.onUpdate);
  }

  componentWillUnmount() {
    window.removeEventListener("mousemove", this.onMouseMove);
    window.removeEventListener("touchmove", this.onMouseMove);
    window.cancelAnimationFrame(this.raf);
  }

  render() {
    const { gameLogo, videoLink, inlineVideoFile, videoThumb, platforms, videoDescriptor, flavorCopy, buyPreorderCta } =
      this.props.data;

    return (
      <HeroSection>
        <HeroLightningClippedContainer>
          <div className="backgroundImg" style={{ backgroundImage: `url("${imgBackground}")` }}></div>
          <div
            className="dustBGTop"
            ref={this.dustBGTop}
            style={{ backgroundImage: `url("${imgBackgroundDust}")` }}
          ></div>

          <div
            className="dustBot"
            ref={this.dustBGBottom}
            style={{ backgroundImage: `url("${imgBackgroundDustBot}")` }}
          ></div>

          <div
            className="charRuinedKing"
            ref={this.charRuinedKing}
            style={{ backgroundImage: `url("${heroChar}")` }}
          ></div>
          <div className="charRuinedKingTablet" style={{ backgroundImage: `url("${heroCharTablet}")` }}></div>
          <div className="charRuinedKingMobile" style={{ backgroundImage: `url("${heroCharMobile}")` }}></div>

          <div className="centerCol">
            <ContentstackImg className="mainLogo" image={{ url: gameLogo.url }} />
            <VideoPreview
              videoOpen={this.props.videoOpen}
              thumbnailImage={videoThumb.url}
              videoURL={inlineVideoFile.url}
              textCaption={videoLink.title}
            ></VideoPreview>
            <div className="infoGroup">
              <div className="text-caption available">{videoDescriptor}</div>
              <div className="platforms">
                {platforms.map((platform) => (
                  <span
                    key={platform.title}
                    dangerouslySetInnerHTML={{ __html: platform.platformLogo.svg.content }}
                  ></span>
                ))}
              </div>
              <div className="text-wrapper">
                <p className="text-paragraph">{flavorCopy}</p>
              </div>
              <ButtonText colorTheme="teal" callback={() => navigate(buyPreorderCta.href)}>
                {buyPreorderCta.title}
              </ButtonText>
            </div>
          </div>
        </HeroLightningClippedContainer>
      </HeroSection>
    );
  }
}
