import React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { ThemeProvider } from "styled-components";
import { ContentstackEmailForm } from "../Riot/EmailForm";
import GridContainer from "../GridContainer";

import { NewsletterSection, TitleContainer, FormContainer, LegalContainer } from "./styles";

export const fragment = graphql`
  fragment GameNewsletterFragment on Contentstack_casper_home_page {
    gameNewsletter: everson_newsletter {
      emailListName: email_list_name
      salesForceExternalKey: salesforce_external_key
      headline
      flavorCopy: flavor_copy
      ctaLabel: cta_label
      termsCopy: terms_copy
      emailFieldLabel: email_field_label
      emailValidationMessage: email_validation_message
      dobLabel: dob_label
      dobValidationMessage: dob_validation_message
      successMessage: success_message
      failureMessage: failure_message
    }
  }
`;

export interface NewsletterData {
  emailListName: string;
  salesForceExternalKey: string;
  headline: string;
  flavorCopy: string;
  ctaLabel: string;
  termsCopy: string;
  emailFieldLabel: string;
  emailValidationMessage: string;
  dobLabel: string;
  dobValidationMessage: string;
  successMessage: string;
  failureMessage: string;
}

interface Props {
  data: NewsletterData;
  locale: string;
}

export default class Newsletter extends React.Component<Props> {
  render() {
    const {
      headline,
      flavorCopy,
      ctaLabel,
      termsCopy,
      emailFieldLabel,
      emailValidationMessage,
      dobLabel,
      dobValidationMessage,
      successMessage,
    } = this.props.data;

    let locationPathname = "/";
    if (typeof window !== "undefined") {
      locationPathname = window.location.pathname;
    }

    return (
      <NewsletterSection id="Newsletter">
        <GridContainer>
          <div className="razorImage">
            <StaticImage src="../../images/razorfin_newsletter.png" alt="" />
          </div>
          <TitleContainer>
            <h2>{headline}</h2>
            <p>{flavorCopy}</p>
          </TitleContainer>
          <FormContainer>
            <ThemeProvider
              theme={{
                email_form_background_image:
                  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVQYV2NgYAAAAAMAAWgmWQ0AAAAASUVORK5CYII=",
              }}
            >
              <ContentstackEmailForm
                globalConfig={{
                  date_of_birth_field_label: dobLabel,
                  date_of_birth_format: "MM/DD/YYYY",
                  email_field_label: emailFieldLabel,
                  error_messages: {
                    invalid_dob: dobValidationMessage,
                    invalid_email: emailValidationMessage,
                  },
                  post_signup_header: successMessage,
                }}
                lang={this.props.locale}
                pageConfig={{
                  age_gating_enabled: true,
                  email_cta: ctaLabel,
                  email_list_name: "Riot Forge Publication List",
                  salesforce_external_key: "RiotAccountData",
                  //email_list_name: 'Riot Universe Publication List',
                  //salesforce_external_key: 'RiotAccountData',
                  subhead: "",
                  title: "",
                  successURL: `${locationPathname}#Newsletter`,
                }}
              />
            </ThemeProvider>
            <LegalContainer>
              <p className="legal" dangerouslySetInnerHTML={{ __html: termsCopy }} />
            </LegalContainer>
          </FormContainer>
        </GridContainer>
      </NewsletterSection>
    );
  }
}
