import React from "react";
import styled from "styled-components";
import { ContentstackImg } from "@riotgames/wwpub-components";
import { breakpoints, colors } from "../../styles/variables";

const ChampCard = styled.div`
  position: relative;
  width: 15%;
  min-width: 209px;
  background: ${colors.teal};
  padding: 8px;
  cursor: pointer;
  box-shadow: 8px 8px 0 ${colors.teal};
  transition: box-shadow 0.5s ease-out;
  margin-bottom: 30px;
  
  .text-miniheader {
    padding: 12px 0;
  }

  .champWindow {
    width: 100%;
    padding-top: 250%;
    position: relative;
    overflow: hidden;
  }
  .champImage {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
  }
  .champImage {
    display: flex;
    transition: transform 0.7s ease-out;
  }
  .champImage img {
    display: flex;
    flex: 1;
    margin: auto;
    width: 100%;
    height: auto;
    background-color: ${colors.green};
  }
  .text-miniheader {
    color: ${colors.black};
    font-size: 17px;
    padding: 24px 0 12px;
    text-align: center;
    text-transform: uppercase;
  }

  &:hover {
    box-shadow: 8px 8px 0 ${colors.black};

    .champImage {
      transform: scale(1.1);
    }
  }

  @media (max-width: 1400px) {
    min-width: 250px;
  }

  @media (max-width: ${breakpoints.tablet}) {
    width: 303px;
    background: ${colors.teal};
    padding: 8px;
    cursor: pointer;
    box-shadow: 8px 8px 0 ${colors.teal};
    transition: box-shadow 0.5s ease-out;
    margin-bottom: 30px;
    .text-miniheader {
      padding: 12px 0;
    }
  }

  @media (max-width: ${breakpoints.mobile}) {
    height: 500px;

    .champWindow {
      height: 94%;
      padding-top: 100%;
    }
    .champImage {
      align-items: center;
    }
  }
`;

interface Props {
  imageSrc: string;
  lightningNo: number;
  linkHref: string;
}

export default class ButtonText extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  openURL = (event: React.MouseEvent): void => {
    window.open(this.props.linkHref, "_new");
  };

  render() {
    return (
      <ChampCard onClick={this.openURL}>
        <div className="champWindow">
          <div className="champImage">
            <ContentstackImg image={{ url: this.props.imageSrc }} />
          </div>
        </div>
        <div className="text-miniheader">{this.props.children}</div>
      </ChampCard>
    );
  }
}
