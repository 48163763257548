import styled from "styled-components";
import { colors, breakpoints } from "../../styles/variables";
import { zIndices } from "../../styles/variables";

export const ChampionSection = styled.section`
  position: relative;

  .innerClippedBgnd {
    width: 100%;
    height: 100%;
    box-sizing: content-box;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    z-index: ${zIndices.lightningClip};
    clip-path: polygon(
      0px 0px,
      100% 0px,
      100% calc(100% - 2vw),
      21% calc(100% - 13vw),
      22% calc(100% - 6.25vw),
      0px calc(100% - 9.5vw)
    );
    display: flex;
    flex-flow: column;
    justify-content: center;
    padding-bottom: 18.5vw;
  }
  .contentDiv {
    grid-column: 1 / span 12;
  }
  .titleBox {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;

    .text-header {
      color: ${colors.teal};
      position: relative;
    }
  }
  .champColumns {
    width: 100%;
    display: grid;
    grid-auto-flow: column;
    justify-content: space-around;
    -webkit-justify-content: space-evenly !important;
  }

  @media (max-width: 1400px) {
    .contentDiv {
      grid-column: 3 / span 8;
    }

    .champColumns {
      width: 100%;
      justify-items: center;
      grid-template-rows: repeat(2, 710px);
      grid-template-columns: repeat(3, 324px);
      align-items: center;
      .champCard {
        width: 100%;
      }
    }
  }

  @media (max-width: ${breakpoints.tablet}) {
    .contentDiv {
      grid-column: 2 / span 6;
    }

    .champColumns {
      justify-items: center;
      grid-template-rows: repeat(3, 800px);
      grid-template-columns: repeat(2, 324px);
      align-items: center;
      .champCard {
        width: 100%;
      }
    }
  }
  @media (max-width: ${breakpoints.mobile}) {
    .contentDiv {
      grid-column: 1 / span 2;
    }
    .fluteImage {
      width: 100px;
    }
    .champColumns {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      .champCard {
        width: 100%;
      }
    }
  }
`;
