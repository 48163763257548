import styled from "styled-components";
import { colors, breakpoints } from "../../styles/variables";
import { SectionVerticalPadding } from "../../styles/mixins";

export const PublisherSection = styled.section`
  ${SectionVerticalPadding};
  position: relative;
  background-color: ${colors.black};
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  .buttonRow {
    grid-column: 1 / span 12;
    text-align: center;
    margin: 50px 0 0 0;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      margin: 150px 0 0 0;
    }
  }
  .logoContainer {
    width: 100%;
    max-width: 420px;
    margin: 0 auto 64px;

    img,
    svg {
      width: 100%;
    }
  }
  @media (max-width: ${breakpoints.tablet}) {
    .buttonRow {
      grid-column: 2 / span 6;
    }
    .logoContainer {
      max-width: none;
      margin: 0 auto 64px;
    }
  }
  @media (max-width: ${breakpoints.mobile}) {
    .buttonRow {
      grid-column: 1 / span 2;
      margin: 30px 0 0 0;
    }
    .logoContainer {
      width: 240px;
      margin: 0 auto 32px;
    }
  }
`;

export const backgroundImage = {
  position: "absolute",
  top: 0,
  left: 0,
  zIndex: 0,
  width: "100%",
  height: "100%",
} as const;

export const Headline = styled.p`
  text-align: center;
  font-family: "Beaufort-Bold";
  font-size: 24px;
  line-height: 130%;
  color: ${colors.white};
  margin-bottom: 32px;

  @media (max-width: ${breakpoints.tablet}) {
    text-align: left;
  }

  @media (max-width: ${breakpoints.mobile}) {
    text-align: center;
    font-size: 20px;
  }
`;

// export const LeftContainer = styled.div`
//   display: none;
//   grid-column: 1 / span 5;

//   .text-miniheader {
//     color: ${colors.white};
//   }

//   p {
//     line-height: 130%;
//     margin: 0;
//   }
//   p + p {
//     margin-top: 1em;
//   }

//   @media (max-width: ${breakpoints.tablet}) {
//     grid-column: 2 / span 6;
//     margin-bottom: 30px;
//   }

//   @media (max-width: ${breakpoints.mobile}) {
//     grid-column: 1 / span 2;
//   }
// `;

export const CenterContainer = styled.div`
  width: 100%;
  max-width: 780px;
  margin: 0 auto;

  .text-paragraph {
    text-align: center;
    line-height: 170%;
    font-size: 18px;
  }

  p + p {
    margin-top: 1em;
  }

  .text-paragraph {
    color: ${colors.cream};
    margin-bottom: 24px;
  }

  @media (max-width: ${breakpoints.tablet}) {
    width: 90vw;
    max-width: 460px;
    .text-paragraph {
      font-size: 16px;
      text-align: center;
    }
  }
`;
