import React from "react";
import { graphql } from "gatsby";
import GridContainer from "../GridContainer";
import ChampionsCard from "./ChampionsCard";
import styled from "styled-components";
import backgroundChampion from "../../images/backgroundChampion.png";
import SectionTitle from "../SectionTitle";
import { zIndices } from "../../styles/variables";
import { ChampionSection } from "./styles";

const SVGLightningLines = styled.svg`
  display: block;
  position: absolute;
  left: 0;
  bottom: 0vw;
  width: 100%;
  height: auto;
  z-index: ${zIndices.lightningClip};
  opacity: 0.3;
`;

export const fragment = graphql`
  fragment GameChampionsFragment on Contentstack_casper_home_page {
    gameChampions: game_champions {
      headline
      champions {
        headline
        thumbnail {
          url
        }
        universeLink: universe_link {
          href
          title
        }
      }
    }
  }
`;

export interface ChampionsData {
  headline: string;
  champions: [
    {
      headline: string;
      thumbnail: {
        url: string;
      };
      universeLink: {
        href: string;
        title: string;
      };
    },
  ];
}

interface Props {
  data: ChampionsData;
}

export default class Champions extends React.Component<Props> {
  render() {
    const { headline, champions } = this.props.data;

    return (
      <ChampionSection>
        <div className="innerClippedBgnd" style={{ backgroundImage: `url("${backgroundChampion}")` }}>
          <GridContainer className="gridContainer">
            <div className="contentDiv">
              <div className="titleBox">
                <SectionTitle colorTheme="dark" title={headline}></SectionTitle>
              </div>
              <div className="champColumns">
                {champions.map((champ, id) => (
                  <ChampionsCard
                    key={champ.headline}
                    lightningNo={id}
                    imageSrc={champ.thumbnail.url}
                    linkHref={champ.universeLink.href}
                  >
                    {champ.headline}
                  </ChampionsCard>
                ))}
              </div>
            </div>
          </GridContainer>
        </div>
        <SVGLightningLines
          width="1440"
          height="196"
          viewBox="0 0 1440 196"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M-867 -37.1006L339.5 135.399L322 36.3994L2429 334.899" stroke="white" />
          <path d="M-859 -45.1006L347.5 127.399L330 28.3994L2437 326.899" stroke="white" />
        </SVGLightningLines>
      </ChampionSection>
    );
  }
}
