import styled from "styled-components";
import { breakpoints, colors } from "../../styles/variables";

export const OverviewContainer = styled.div`
  position: relative;
  background: ${colors.black};
  margin: 20px 0 100px;

  @media (max-width: ${breakpoints.tablet}) {
    margin: 20px 0 50px;
  }
  .text-header {
    text-align: left;
    color: ${colors.teal};
    margin-top: 10px;
  }
  .text-subheader {
    color: ${colors.gold};
    font-size: 20px;
  }
  .text-paragraph {
    color: ${colors.white};
  }
`;

export const ContentDiv = styled.div`
  position: relative;

  @media (max-width: ${breakpoints.tablet}) {
    padding: 5.5vw 105px;
  }

  @media (max-width: ${breakpoints.mobile}) {
    padding: 5.5vw 0px;
  }
`;

export const LeftContainer = styled.div`
  grid-column: 2 / span 5;
  grid-row: 2;

  > p {
    font-family: "ProximaNova-Bold";
    color: ${colors.gold};
    font-size: 20px;
    line-height: 130%;
    margin: 0;
  }

  @media (max-width: ${breakpoints.laptop}) {
    > p {
      font-size: 24px;
    }
  }

  @media (max-width: ${breakpoints.tablet}) {
    grid-column: 2 / span 6;

    > p {
      margin: 0 0 48px 0;
    }
  }

  @media (max-width: ${breakpoints.mobile}) {
    grid-column: 1 / span 2;

    > p {
      font-size: 18px;
    }
  }
`;

export const RightContainer = styled.div`
  grid-column: 7 / span 5;
  grid-row: 2;

  > p {
    font-family: "ProximaNova-Regular";
    color: ${colors.white};
    font-size: 18px;
    line-height: 170%;
    margin: 0;
  }

  > p:not(:last-of-type) {
    margin: 0 0 24px 0;
  }

  @media (max-width: ${breakpoints.laptop}) {
    > p {
      font-size: 16px;
    }
  }

  @media (max-width: ${breakpoints.tablet}) {
    grid-column: 2 / span 6;
    grid-row: 3;
  }

  @media (max-width: ${breakpoints.mobile}) {
    grid-column: 1 / span 2;

    > p {
      font-size: 16px;
      line-height: 150%;
    }
  }
`;
