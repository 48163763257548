import React from "react";
import _ from "lodash";
import { graphql } from "gatsby";
import GridContainer from "../GridContainer";
import FeatureSlide from "../molecules/FeatureSlide/FeatureSlide";
import SectionTitle from "../SectionTitle";
import styled from "styled-components";
import { FeaturesContainer } from "./styles";
import { mod } from "../../helpers/utils";
import { zIndices } from "../../styles/variables";

import imgBackground from "../../images/backgroundFeatures.jpg";

const SVGLightningLines = styled.svg`
  display: block;
  position: absolute;
  left: 0;
  bottom: 0vw;
  width: 100%;
  height: auto;
  z-index: ${zIndices.lightningClip};
  opacity: 0.3;
`;

export const fragment = graphql`
  fragment GameFeaturesFragment on Contentstack_casper_home_page {
    gameFeatures: game_features {
      headline
      description
      gameFeature: game_feature {
        headline
        copyBlurb: copy_blurb
        thumbnailImage: thumbnail_image {
          url
        }
        inlineVideo: inline_video {
          url
        }
        isVideo: is_video
      }
    }
  }
`;

export interface FeaturesData {
  headline: string;
  description: string;
  gameFeature: [
    {
      headline: string;
      copyBlurb: string;
      thumbnailImage: {
        url: string;
      };
      inlineVideo: {
        url: string;
      };
      isVideo: boolean;
    },
  ];
}

interface Props {
  data: FeaturesData;
}
interface State {
  slide: number;
}

const arrowSVG = (
  <svg className="pagSVG" viewBox="0 0 41 41">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.5685 36.6466V27.8717L16.5464 19.8495L24.5685 11.8274V3.05249L22.3748 5.24618L9.96522 17.6558L7.77148 19.8495L9.96522 22.0433L22.3748 34.4529L24.5685 36.6466ZM25.101 19.8499L29.4884 15.4625L33.8759 19.8499L29.4884 24.2374L25.101 19.8499Z"
      className="pagArrowPath"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.5685 36.6466V27.8717L16.5464 19.8495L24.5685 11.8274V3.05249L22.3748 5.24618L9.96522 17.6558L7.77148 19.8495L9.96522 22.0433L22.3748 34.4529L24.5685 36.6466ZM25.101 19.8499L29.4884 15.4625L33.8759 19.8499L29.4884 24.2374L25.101 19.8499Z  "
      className="pagArrowHover"
      transform-origin="20 25"
    />
  </svg>
);

export default class Features extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      slide: -1,
    };
  }

  arrowClick = (dir: number) => {
    let newSlide = mod(this.state.slide + dir, this.props.data.gameFeature.length);

    this.setState({ slide: newSlide });
  };

  pagClick = (newSlide: number) => {
    this.setState({ slide: newSlide });
  };

  renderPaginationWidgets() {
    const allPaginationWidgets = _.range(this.props.data.gameFeature.length).map((index) => {
      const activeClass = this.state.slide === index ? "active" : "";
      const padNumber = String(index + 1).padStart(2, "0");

      return (
        <div className={`pagWidget ${activeClass}`} key={`pag-${index}`} onClick={() => this.pagClick(index)}>
          <div className="pagNum">{padNumber}</div>
          <div className="pagBar">
            <div className="pagProgress"></div>
          </div>
        </div>
      );
    });

    return allPaginationWidgets;
  }

  renderSlides() {
    const slideData = this.props.data.gameFeature.map((feature) => ({
      img: feature.thumbnailImage.url,
      videoURL: feature.isVideo ? feature.inlineVideo.url : null,
      title: feature.headline,
      desc: feature.copyBlurb,
    }));

    const allImages = slideData.map((slide: any, index: number) => {
      const activeClass = this.state.slide === index ? "active" : "";
      const padNumber = String(index + 1).padStart(2, "0");

      return (
        <FeatureSlide
          slideData={slide}
          slideIndex={index}
          activeSlide={this.state.slide}
          key={`slide-${index}`}
        ></FeatureSlide>
      );
    });
    return allImages;
  }

  componentDidMount() {
    // Init first slide
    this.setState({ slide: 0 });
  }

  render() {
    const { headline, description } = this.props.data;

    return (
      <FeaturesContainer>
        <div className="innerClippedBgnd" style={{ backgroundImage: `url("${imgBackground}")` }}>
          <GridContainer className="gridContainer">
            <SectionTitle colorTheme="dark" title={headline}>
              {description}
            </SectionTitle>
            <div className="layoutBox">
              {/* SLIDES */}
              <div className="slideBox">{this.renderSlides()}</div>

              {/* PAGINATION */}
              <div className="pagination">
                <div className="pagArrow pagArrowLeft" onClick={() => this.arrowClick(-1)}>
                  {arrowSVG}
                </div>
                <div className="pagRow">{this.renderPaginationWidgets()}</div>
                <div className="pagArrow pagArrowRight" onClick={() => this.arrowClick(+1)}>
                  {arrowSVG}
                </div>
              </div>
            </div>
          </GridContainer>
        </div>
        <SVGLightningLines
          width="1440"
          height="196"
          viewBox="0 0 1440 196"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M2299 -45.1006L1092.5 127.399L1110 28.3994L-997 326.899" stroke="white" />
          <path d="M2307 -37.1006L1100.5 135.399L1118 36.3994L-989 334.899" stroke="white" />
        </SVGLightningLines>
      </FeaturesContainer>
    );
  }
}
